import React, { useState, useMemo } from "react";

import "./index.scss";
import "../../scss/main.scss";

//routing
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Counter from "../../components/Counter";
import DynamicText from "../../components/DynamicText";
import BarChart from "../../components/Graphics/BarChart";
import Triptych from "../../components/Cards/Triptych";
import Footer from "../../components/Footer";

import { loadPhotosJson } from "../../utilities";

const AboutMe = (props) => {
  const [jsonPhoto, setJsonPhoto] = useState([]);

  const romaText =
    "Roma, un museo a cielo aperto, offre infinite opportunità fotografiche. Dai vicoli pittoreschi di Trastevere al maestoso Colosseo, ogni angolo racconta una storia. Le luci calde del tramonto trasformano la città in uno spettacolo visivo, perfetto per catturare scatti unici e indimenticabili";
  const torinoText =
    "Torino, elegante e affascinante, offre scorci fotografici unici. Le maestose piazze, i portici storici e la Mole Antonelliana creano un mix perfetto di storia e modernità. Le Alpi sullo sfondo e il Parco del Valentino aggiungono un tocco naturale, rendendo ogni scatto un racconto visivo di bellezza e cultura";
  const veneziaText =
    "venezia, con i suoi canali sinuosi e palazzi storici, è un paradiso per i fotografi. le gondole che scivolano sull'acqua, i riflessi dorati al tramonto e le maschere del Carnevale offrono scatti mozzafiato. ogni angolo della città evoca magia e romanticismo, rendendo ogni foto un capolavoro";
  const valDOrciaText =
    "La Val d’Orcia, con le sue colline ondulate e i cipressi solitari, è un sogno per i fotografi. I borghi medievali, i vigneti e i campi dorati offrono scenari idilliaci in ogni stagione. Qui, la luce morbida al tramonto e all’alba trasforma ogni angolo in un dipinto da catturare";

  useMemo(() => {
    window.scrollTo(0, 0);

    const jsonPhotoOrigin = loadPhotosJson();
    setJsonPhoto(jsonPhotoOrigin);

    //imposto il nome della pagina correttamente
    document.title = process.env.REACT_APP_APP_NAME_ABR + " - About me";
  }, []);

  return (
    <React.Fragment>
      <Header />
      <main className="about-me">
        <div className="about-me__background font-title">
          <DynamicText text={"About me"} isCapitalize={true} />
        </div>
        <div className="about-me__perfil">
          <div className="card_perfil">
            <div className="card_perfil__image">
              <img
                src={require(`../../images/aboutMe2.jpg`)}
                alt={"Foto di riccardo biffi"}
                loading="lazy"
              />
              <span>@Riccardo Biffi {new Date().getFullYear()}</span>
            </div>
            <div className="card_perfil__text">
              <p>
                <DynamicText
                  text={
                    "Sono un fotografo italiano amatoriale che ama immortalare natura, dettagli e paesaggi."
                  }
                  isCapitalize={true}
                />
              </p>
              <p>
                <DynamicText
                  text={
                    "Ho iniziato la mia esperienza nel 2013 e da allora viaggio alla ricerca dello scatto perfetto."
                  }
                  isCapitalize={true}
                />
              </p>
              <p>
                <DynamicText
                  text={
                    "Mi sono posto come obbiettivo quello di trasmettere la mia passione per la natura e per tutto ciò che ci circonda."
                  }
                  isCapitalize={true}
                />
              </p>
              <p>
                <DynamicText
                  text={"Sono originario della lombardia risiedo a Lodi."}
                  isCapitalize={true}
                />
              </p>
              <div className="maps">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d197293.8141278835!2d-0.5263224508168879!3d39.40793427415823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd604f4cf0efb06f%3A0xb4a351011f7f1d39!2sValencia%2C%20Provincia%20di%20Valencia!5e0!3m2!1sit!2ses!4v1741356103192!5m2!1sit!2ses"
                  width="600"
                  height="450"
                  style={{ border: "0" }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>

          <div className="about-me__counters">
            <Counter
              number={new Date().getFullYear() - 2013}
              text={"Anni di esperienza"}
              color={"light-blue-plus"}
            />
            <Counter
              number={jsonPhoto?.foto?.length}
              text={"Fotografie pubblicate"}
              color={"light-blue"}
            />
            <Counter number={6} text={"Fotocamere cambiate"} color={"light-blue-min"} />
          </div>
          <div className="about-me__graph">
            <BarChart arrayPhoto={jsonPhoto.foto} />
          </div>
          <div className="about-me__social">
            <Link
              to={"https://www.instagram.com/riccardo.biffi.ph?igsh=N3Y5anJyOThrbWJ0"}>
              <div className="social-element">
                <img
                  src={require(`../../images/insta.webp`)}
                  alt={"Instagram"}
                  loading="lazy"
                />
                <span>@riccardo.biffi.ph</span>
              </div>
            </Link>
            <Link to={"https://www.facebook.com/biffi.ricky"}>
              <div className="social-element">
                <img
                  src={require(`../../images/facebook.png`)}
                  alt={"Instagram"}
                  loading="lazy"
                />
                <span>@Riccardo Biffi</span>
              </div>
            </Link>
            <Link to={"mailto:biffi-riccardo@virgilio.it"}>
              <div className="social-element">
                <img
                  src={require(`../../images/email.webp`)}
                  alt={"email"}
                  loading="lazy"
                />
                <span>@e-email</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="content-item centered">
          <div className="content-item__content">
            <div className="title">
              <DynamicText text={"Guarda anche..."} />
            </div>
            <div className="triptych-cont">
              <Triptych
                title={"Val D'Orcia"}
                subtitle={"maggio 2024"}
                text={valDOrciaText}
                link={process.env.REACT_APP_ROUTE_PHOTO_CITY_VALDORCIA}
                pathsImage={[
                  "2024_it_valDOrcia6_siena",
                  "2024_it_valDOrcia2_siena",
                  "2024_it_valDOrcia4_siena",
                ]}
                isDark={true}
              />
              <Triptych
                title={"venezia"}
                subtitle={"aprile 2024"}
                text={veneziaText}
                link={"/photos/city=venezia&year=2024&title=la-serenissima"}
                pathsImage={[
                  "2024_it_workshop2_venezia",
                  "2024_it_workshop4_venezia",
                  "2024_it_workshop10_venezia",
                ]}
                isReverse={true}
                isDark={true}
              />
              {/* <Triptych
							title={"Torino"}
							subtitle={"gennaio 2024"}
							text={torinoText}
							link={"/photos/city=torino&year=2024&title=la-citta-dei-quattro-fiumi"}
							pathsImage={["2024_it_panorama2_torino", "2024_it_panorama4_torino", "2024_it_panorama8_torino"]}
						/>
						<Triptych
							title={"Roma"}
							subtitle={"marzo 2023"}
							text={romaText}
							link={"/photos/city=roma&year=2023&title=la-citta-eterna"}
							pathsImage={["2023_it_albaColosseo3_roma", "2023_it_rovine2_roma", "2023_it_tramonto6_roma"]}
							isReverse={true}
						/> */}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default AboutMe;
